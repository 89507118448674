import { useEffect, useState } from 'react';
import { Table, Tr, Td, Th, Thead, Tbody, Flex, useDisclosure, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  Loader,
  PaginationWrapper,
  TableActionButton,
  TableDataStatus,
  ViewMore,
  UpdateWithdrawalStatus,
  WalletWithdrawalModal,
  ScrollRightContainer,
} from 'components';
import { formatDateToUTC } from 'utils';
import { fetchSingleWithdrawalData } from 'store/slices/wallet.slice';

const UserWithdrawal = ({ userId }) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [type, setType] = useState('');
  const [txStatus, setTxStatus] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [size, setSize] = useState(10);
  const [actionValue, setActionValue] = useState('');

  const { data, status } = useSelector((state) => state.wallet.singleWithdraw);

  const handleOpen = (data) => {
    onOpen();
    setSelectedData(data);
    setActionValue('');
  };

  const handleActions = (e, data) => {
    setActionValue(e.target.value);
    onOpen();
    setSelectedData(data);
  };

  const handleWidthrawalType = (e) => {
    setType(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setTxStatus(e.target.value);
  };

  const handleSize = (e) => {
    setSize(e.target.value);
  };

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, pageSize, offset } = usePagination({
    initialState: { currentPage: 1, pageSize: size },
    total: data.totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  useEffect(() => {
    dispatch(
      fetchSingleWithdrawalData({
        userId,
        page: currentPage,
        limit: size,
        withdrawalType: type,
        status: txStatus,
      })
    );
  }, [dispatch, pageSize, currentPage, type, setCurrentPage, txStatus, size, userId]);

  return (
    <>
      {status === 'loading' && <Loader />}

      {status === 'fulfilled' && data.totalCount === 0 && (
        <Text
          fontSize='18px'
          fontWeight='500'
        >
          User has no withdrawal
        </Text>
      )}

      {(data && data.withdrawals).length >= 1 && (
        <>
          <Flex
            gridGap='10px'
            mt='38px'
            mb='48px'
            width='100%'
          >
            <FilterSelect
              width='92px'
              onChange={handleWidthrawalType}
              defaultValue={type}
            >
              <option value=''>All </option>
              <option value='fiat'>Fiat</option>
              <option value='crypto'>Crypto</option>
            </FilterSelect>

            <FilterSelect
              width='88px'
              onChange={handleSize}
            >
              <option value={10}>Size</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </FilterSelect>

            <FilterSelect
              width='109px'
              onChange={handleStatusChange}
              placeholder='Status'
            >
              <option value='approved'>Approved</option>
              <option value='cancelled'>Cancelled</option>
              <option value='denied'>Denied</option>
              <option value='paid'>Paid</option>
              <option value='cleared'>Cleared</option>
              <option value='pending'>Pending</option>
            </FilterSelect>
          </Flex>

          <ScrollRightContainer>
            <Table overflow='hidden'>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>Date & Time</Th>
                  <Th>Source</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.withdrawals.map(
                    (
                      {
                        _id,
                        user,
                        createdAt,
                        coin,
                        withdrawalType,
                        status,
                        amount,
                        bankDetails,
                        addressRef,
                        txRef,
                        destination,
                        withdrawalFee,
                        approvedBy,
                        txLink,
                        txHash,
                        canceledBy,
                        deniedBy,
                        enterpriseWithdrawalId,
                        payoutId,
                      },
                      index
                    ) => {
                      const modalData = {
                        _id,
                        user,
                        createdAt,
                        coin,
                        withdrawalType,
                        status,
                        amount,
                        bankDetails,
                        addressRef,
                        txRef,
                        destination,
                        withdrawalFee,
                        approvedBy,
                        txLink,
                        txHash,
                        canceledBy,
                        deniedBy,
                        enterpriseWithdrawalId,
                        payoutId,
                      };

                      return (
                        <Tr key={_id}>
                          <Td>{index + 1}</Td>

                          <Td>{user.fullName}</Td>

                          <Td>
                            {coin === 'ngn' && <span>&#x20A6;</span>}
                            {coin === 'ghs' && <span>&#8373;</span>}
                            {coin === 'kes' && 'KSh'}{' '}
                            {withdrawalType === 'crypto' ? amount : Number(amount).toLocaleString()}
                            &nbsp;
                            {withdrawalType === 'crypto' && (
                              <Text
                                textTransform='uppercase'
                                as='span'
                              >
                                {coin}
                              </Text>
                            )}
                          </Td>

                          <Td>{formatDateToUTC(createdAt)}</Td>

                          <Td textTransform='capitalize'>{withdrawalType}</Td>

                          <Td textTransform='capitalize'>
                            <Flex
                              gridGap='0.5rem'
                              alignItems='center'
                            >
                              <TableDataStatus status={status} />
                              <>
                                {status === 'pending' && (
                                  <UpdateWithdrawalStatus
                                    _id={_id}
                                    data={selectedData}
                                    txStatus={txStatus}
                                  />
                                )}
                              </>
                            </Flex>
                          </Td>

                          <Td
                            display='flex'
                            gridGap='8px'
                          >
                            {status === 'approved' || status === 'cleared' || status === 'denied' ? (
                              <ViewMore onClick={() => handleOpen(modalData)}>View more</ViewMore>
                            ) : (
                              <TableActionButton onClick={(e) => handleActions(e, modalData)} />
                            )}
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <WalletWithdrawalModal
            isOpen={isOpen}
            onClose={onClose}
            data={selectedData}
            actionValue={actionValue}
            currentPage={currentPage}
            pageSize={pageSize}
            type={type}
          />

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={data.totalCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            offset={offset}
            pages={pages}
            size={size}
          />
        </>
      )}
    </>
  );
};

export default UserWithdrawal;
