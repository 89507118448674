import { Modal, ModalOverlay, ModalContent, ModalBody, Text, Flex, Box, Image } from '@chakra-ui/react';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { formatDateToUTC } from 'utils';
import {
  ApproveDenyFiatDeposit,
  TableDataStatus,
  CloseModal,
  ProcessDeposit,
  UpdateDepositAmount,
  CurrencyFormat,
} from 'components';
import { useCopy, useSetUserStorage } from 'hooks';

const WalletDepositModal = ({ isOpen, onClose, data, actionValue, currentPage }) => {
  const {
    _id,
    amount,
    createdAt,
    currency,
    depositPaymentType,
    userDetails,
    reference,
    status,
    txRefId,
    depositBankTo,
    paymentUploadUrl,
    version,
    fee,
    approvedBy,
  } = data;

  const { hasCopied, onCopy, value } = useCopy();

  useSetUserStorage(userDetails?.userId, userDetails?.fullName);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      key={_id}
    >
      <ModalOverlay />

      <ModalContent
        borderRadius='16px'
        bg='#fff'
        maxWidth='568px'
        padding={{ base: '40px 16px 0', md: '40px 40px 0' }}
      >
        <Text
          fontSize='16px'
          textAlign='center'
          fontWeight='600'
        >
          Transaction Details
        </Text>

        <ModalBody
          padding='0px'
          margin='40px 0'
          fontSize='14px'
        >
          <Box>
            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Full name</Text>

              <Text fontWeight='500'>{userDetails?.fullName}</Text>
            </Flex>

            <Flex
              mb='16px'
              justifyContent='space-between'
            >
              <Text>Email</Text>

              <Text
                fontWeight='500'
                textTransform='lowercase'
              >
                <a
                  href={`/dashboard/users/${userDetails?.userId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {userDetails?.email}
                </a>
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Deposit ID</Text>

              <Flex
                alignItems='center'
                gap='4px'
                cursor='pointer'
                onClick={() => onCopy(_id)}
              >
                <Text fontWeight='500'>{_id}</Text>

                {value === _id && hasCopied ? <CheckIcon /> : <CopyIcon />}
              </Flex>
            </Flex>

            {version && (
              <Flex
                justifyContent='space-between'
                mb='16px'
              >
                <Text>Version</Text>

                <Text
                  fontWeight='500'
                  textTransform='uppercase'
                >
                  {version}
                </Text>
              </Flex>
            )}

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Amount</Text>

              <UpdateDepositAmount
                _id={_id}
                amount={amount}
                currency={currency}
                currentPage={currentPage}
                status={status}
              />
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Fee</Text>

              <Text fontWeight='500'>
                <CurrencyFormat currency={currency} />

                {fee}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Amount Expected</Text>

              <Text
                fontWeight='500'
                textTransform='uppercase'
              >
                <CurrencyFormat currency={currency} />
                {(Number(fee) + Number(amount?.$numberDecimal)).toLocaleString()}
              </Text>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Status</Text>

              <Box>
                <TableDataStatus
                  status={status}
                  withIcon={false}
                />
              </Box>
            </Flex>

            {approvedBy?.email && (
              <Flex
                justifyContent='space-between'
                mb='16px'
              >
                <Text>Approved By</Text>

                <Text
                  fontWeight='500'
                  textTransform='uppercase'
                >
                  {approvedBy.email}
                </Text>
              </Flex>
            )}

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Reference</Text>

              <Flex
                alignItems='center'
                gap='4px'
                onClick={() => onCopy(reference)}
                cursor='pointer'
              >
                <Text
                  fontWeight='500'
                  textTransform='uppercase'
                >
                  {reference}
                </Text>
                <Box>{value === reference && hasCopied ? <CheckIcon /> : <CopyIcon />}</Box>
              </Flex>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Transaction Reference ID</Text>

              <Flex
                onClick={() => onCopy(txRefId)}
                cursor='pointer'
              >
                <Text fontWeight='500'>{txRefId} &nbsp;</Text>
                <Box>{value === txRefId && hasCopied ? <CheckIcon /> : <CopyIcon />}</Box>
              </Flex>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Source</Text>

              <Text
                fontWeight='500'
                textTransform='capitalize'
              >
                {depositPaymentType}
              </Text>
            </Flex>

            {paymentUploadUrl && (
              <Flex
                justifyContent='space-between'
                mb='16px'
              >
                <Text>Payment Upload URL</Text>

                <a
                  href={paymentUploadUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image
                    src={paymentUploadUrl}
                    alt='Payment Upload'
                    width='100px'
                    height='100px'
                  />
                </a>
              </Flex>
            )}

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Box>
                <Text>Merchant details</Text>
              </Box>

              <Box>
                <Text
                  fontWeight='500'
                  textTransform='capitalize'
                >
                  {depositBankTo?.bankAccountName}
                </Text>

                <Text
                  fontWeight='500'
                  textTransform='capitalize'
                  onClick={() => onCopy(depositBankTo?.bankAccountNumber)}
                  cursor='pointer'
                >
                  {depositBankTo?.bankAccountNumber}{' '}
                  {value === depositBankTo?.bankAccountNumber && hasCopied ? (
                    <CheckIcon />
                  ) : (
                    <CopyIcon cursor='pointer' />
                  )}
                </Text>

                <Text
                  fontWeight='500'
                  textTransform='capitalize'
                >
                  {depositBankTo?.bankName}
                </Text>
              </Box>
            </Flex>

            <Flex
              justifyContent='space-between'
              mb='16px'
            >
              <Text>Time and Date</Text>

              <Text fontWeight='500'>{formatDateToUTC(createdAt)}</Text>
            </Flex>
          </Box>

          {(actionValue === 'approve' || actionValue === 'deny') && (
            <ApproveDenyFiatDeposit
              _id={_id}
              onClose={onClose}
              actionValue={actionValue}
            />
          )}

          {(actionValue === 'paid' || actionValue === 'cleared') && (
            <ProcessDeposit
              _id={_id}
              onClose={onClose}
              newStatus={actionValue}
              // statusFilter={statusFilter}
            />
          )}

          <CloseModal onClick={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WalletDepositModal;
