import { useEffect, useState } from 'react';
import { Box, Table, Tr, Td, Th, Thead, Tbody, Flex, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from '@ajna/pagination';
import {
  FilterSelect,
  PaginationWrapper,
  ScrollRightContainer,
  Select,
  TableActionButton,
  TableDataStatus,
  ViewMore,
  WalletCryptoDepositTable,
  SearchInput,
  WalletDepositModal,
  Refresh,
  UserTransactionModal,
  CurrencyFormat,
} from 'components';
import { constant, formatDateToUTC } from 'utils';
import { fetchCryptoWalletDeposit, fetchWalletDeposit } from 'store/slices/wallet.slice';
import { useDebounce, useStorage } from 'hooks';

import { useNavigate } from 'react-router-dom';
const WalletDepositFiatTable = () => {
  const dispatch = useDispatch();

  const { data, status, totalCount } = useSelector((state) => state.wallet.deposit);

  const { status: cryptoStatus } = useSelector((state) => state.wallet.cryptoDeposit);

  const { currentPage, setCurrentPage, pagesCount, pages, isDisabled, offset, pageSize } = usePagination({
    initialState: { currentPage: 1, pageSize: 10 },
    total: totalCount,
    limits: {
      inner: 2,
      outer: 1,
    },
  });

  const { storageValue, setStorage } = useStorage(constant.DEPOSIT_TYPE);
  const { storageValue: statusStorage, setStorage: setStatusStorage } = useStorage(constant.DE_STATUS);
  const { storageValue: sizeStorage, setStorage: setSizeStorage } = useStorage(constant.DE_SIZE);
  const { storageValue: searchStorage, setStorage: setSearchStorage } = useStorage(constant.DE_SEARCH);
  const { storageValue: sourceStorage, setStorage: setSourceStorage } = useStorage(constant.DE_SOURCE);
  const { storageValue: coinStorage, setStorage: setCoinStorage } = useStorage(constant.DE_COIN);

  const [queryValue, setQueryValue] = useState('');
  const [queryType, setQueryType] = useState(searchStorage ? searchStorage : '');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [depositPaymentType, setDepositPaymentType] = useState(sourceStorage ? sourceStorage : '');
  const [txStatus, setTxStatus] = useState(statusStorage ? statusStorage : '');
  const [selectedData, setSelectedData] = useState({});
  const [size, setSize] = useState(sizeStorage ? sizeStorage : 10);
  const [coinType, setCoinType] = useState(coinStorage ? coinStorage : '');

  const navigate = useNavigate();
  const handleDepositType = (e) => {
    setStorage(e.target.value);
    navigate(`${e.target.value}`);

    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setTxStatus(e.target.value);
    setStatusStorage(e.target.value);
  };

  const handleDepositPaymentType = (e) => {
    setCurrentPage(1);
    setDepositPaymentType(e.target.value);
    setSourceStorage(e.target.value);
  };

  const handleQueryType = (e) => {
    setQueryType(e.target.value);
    setSearchStorage(e.target.value);
  };

  const handleSize = (e) => {
    setSize(e.target.value);
    setSizeStorage(e.target.value);
  };

  const handleCoinType = (e) => {
    setCoinType(e.target.value);
    setCoinStorage(e.target.value);
  };

  const [actionValue, setActionValue] = useState('');

  const handleActions = (e, data) => {
    setActionValue(e.target.value);
    onOpen();
    setSelectedData(data);
  };

  const handleSearch = (e) => {
    const query = e.target.value;

    if (query.length >= 3) {
      setQueryValue(query);
      setCurrentPage(1);
    } else {
      setQueryValue('');
      setCurrentPage(1);
    }
  };

  const debouncedChangeHandler = useDebounce(handleSearch);

  useEffect(() => {
    dispatch(
      fetchWalletDeposit({
        page: currentPage,
        limit: size,
        depositPaymentType: depositPaymentType,
        status: txStatus,
        queries: `${queryType}=${queryValue}`,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, depositPaymentType, dispatch, queryValue, size, txStatus]);

  const handleRefresh = () => {
    storageValue === '?type=crypto'
      ? dispatch(
          fetchCryptoWalletDeposit({
            page: currentPage,
            limit: size,
            unit: coinType,
          })
        )
      : dispatch(
          fetchWalletDeposit({
            page: currentPage,
            limit: size,
            depositPaymentType: depositPaymentType,
            status: txStatus,
            queries: `${queryType}=${queryValue}`,
          })
        );
  };

  return (
    <Box>
      <Box pos='relative'>
        <Flex
          mt='38px'
          mb='48px'
          alignItems='center'
          justifyContent='space-between'
        >
          <Flex
            gridGap='10px'
            flexWrap={{ base: 'wrap', md: 'initial' }}
          >
            <FilterSelect
              width='92px'
              onChange={handleDepositType}
              defaultValue={storageValue === '?type=crypto' ? '?type=crypto' : ''}
            >
              <option value=''>Fiat</option>
              <option value='?type=crypto'>Crypto</option>
            </FilterSelect>

            <FilterSelect
              width='88px'
              onChange={handleSize}
              defaultValue={size}
            >
              <option value={10}>Size</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </FilterSelect>

            {(storageValue === '' || storageValue === null || storageValue === '?type=fiat' || storageValue === '') && (
              <>
                <FilterSelect
                  width='109px'
                  onChange={handleStatusChange}
                  placeholder='Status'
                  defaultValue={txStatus ? txStatus : 'Status'}
                >
                  <option value='approved'>Approved</option>
                  <option value='cancelled'>Cancelled</option>
                  <option value='denied'>Denied</option>
                  <option value='paid'>Paid</option>
                  <option value='cleared'>Cleared</option>
                  <option value='pending'>Pending</option>
                </FilterSelect>

                <FilterSelect
                  width='120px'
                  onChange={handleDepositPaymentType}
                  placeholder='Source'
                  defaultValue={sourceStorage ? sourceStorage : 'Source'}
                >
                  <option value='bank'>Bank</option>
                  <option value='flutterwave'>Flutterwave</option>
                </FilterSelect>

                <FilterSelect
                  width='110px'
                  onChange={handleQueryType}
                  defaultValue={searchStorage}
                >
                  <option>Filter</option>
                  <option value='amount'>Amount</option>
                  <option value='userSearch'>User</option>
                  <option value='reference'>Reference</option>
                </FilterSelect>

                <Box
                  alignSelf='center'
                  width='100%'
                >
                  <SearchInput
                    placeholder='Search amount, user, reference'
                    minH='48px'
                    width={{ base: '100%', tb: '319px' }}
                    onChange={debouncedChangeHandler}
                    disabled={queryType.length === 0}
                  />
                </Box>
              </>
            )}

            {storageValue === '?type=crypto' && (
              <FilterSelect
                width='90px'
                onChange={handleCoinType}
                placeholder='Unit'
                defaultValue={coinType ? coinType : 'Unit'}
              >
                <option value='btc'>BTC</option>
                <option value='teth'>ETH</option>
                <option value='cusd'>CUSD</option>
                <option value='usdt'>USDT</option>
                <option value='ceur'>CEUR</option>
                <option value='celo'>CELO</option>
                <option value='xlm'>XLM</option>
                <option value='xrp'>XRP</option>
              </FilterSelect>
            )}
          </Flex>

          <Refresh
            isLoading={status === 'loading' || cryptoStatus === 'loading'}
            onClick={handleRefresh}
            display={{ sm: 'none', tb: 'flex' }}
          />
        </Flex>

        <Box
          pos='absolute'
          top='30%'
          right='0'
        >
          <Refresh
            isLoading={status === 'loading' || cryptoStatus === 'loading'}
            onClick={handleRefresh}
            display={{ sm: 'flex', tb: 'none' }}
          />
        </Box>
      </Box>

      {(storageValue === '' || storageValue === null || storageValue === '?type=fiat') && (
        <>
          <ScrollRightContainer minHeight='30vh'>
            <Table overflow='auto'>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>Date & Time</Th>
                  <Th>Source</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.deposits.map(
                    (
                      {
                        _id,
                        amount,
                        createdAt,
                        currency,
                        depositPaymentType,
                        userDetails,
                        reference,
                        status,
                        txRefId,
                        depositBankTo,
                        paymentUploadUrl,
                        version,
                        fee,
                        approvedBy,
                      },
                      index
                    ) => {
                      const modalData = {
                        _id,
                        amount,
                        createdAt,
                        currency,
                        depositPaymentType,
                        userDetails,
                        reference,
                        status,
                        txRefId,
                        depositBankTo,
                        paymentUploadUrl,
                        version,
                        fee,
                        approvedBy,
                      };
                      return (
                        <Tr key={_id}>
                          <Td>{index + 1}</Td>

                          <Td>
                            <UserTransactionModal
                              userId={userDetails.userId}
                              fullName={userDetails?.fullName}
                            />
                          </Td>

                          <Td>
                            <CurrencyFormat currency={currency} />
                            &nbsp;
                            {amount && Number(amount?.$numberDecimal).toLocaleString()}
                          </Td>

                          <Td>{formatDateToUTC(createdAt)}</Td>

                          <Td textTransform='capitalize'>{depositPaymentType}</Td>

                          <Td>
                            <TableDataStatus status={status} />
                          </Td>

                          <Td>
                            <Flex
                              gridGap='0.5rem'
                              alignItems='center'
                            >
                              <Box>
                                {depositPaymentType === 'flutterwave' ||
                                status === 'denied' ||
                                status === 'approved' ||
                                status === 'cancelled' ? (
                                  <>
                                    <ViewMore onClick={(e) => handleActions(e, modalData)}>View more</ViewMore>
                                  </>
                                ) : (
                                  <Flex gridGap={'12px'}>
                                    <TableActionButton onClick={(e) => handleActions(e, modalData)} />
                                  </Flex>
                                )}
                              </Box>

                              <Box>
                                {(status === 'pending' || status === 'cancelled') && (
                                  <Select
                                    minW='100px'
                                    onChange={(e) => handleActions(e, modalData)}
                                  >
                                    <option>Process</option>
                                    <option value='paid'>Paid</option>
                                    <option value='cleared'>Cleared</option>
                                  </Select>
                                )}
                              </Box>
                            </Flex>
                          </Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </ScrollRightContainer>

          <WalletDepositModal
            isOpen={isOpen}
            onClose={onClose}
            data={selectedData}
            actionValue={actionValue}
            currentPage={currentPage}
            pageSize={pageSize}
            // statusFilter={txStatus}
          />

          <PaginationWrapper
            setCurrentPage={setCurrentPage}
            totalDataCount={totalCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            pagesCount={pagesCount}
            offset={offset}
            pages={pages}
            size={size}
          />
        </>
      )}

      {storageValue === '?type=crypto' && (
        <WalletCryptoDepositTable
          size={size}
          coinType={coinType}
        />
      )}
    </Box>
  );
};

export default WalletDepositFiatTable;
